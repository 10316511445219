<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="WxappTopicList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="小程序名字" align="center" prop="wxappName" />
<!--      <el-table-column label="小程序id" align="center" prop="wxappId" />-->
<!--      <el-table-column label="题目id" align="center" prop="topicId" />-->
      <el-table-column label="题目" align="center" prop="topicTitle" />
<!--      <el-table-column label="推广码" align="center" prop="code" />-->
            <el-table-column align="center" label="推广码" >

              　<template slot-scope="scope">
              　　　　<img :src="scope.row.code" height="50" width="50" />
              　　</template>

            </el-table-column>
      <el-table-column label="推广图片链接" align="center" prop="code" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
<!--          <el-button-->
<!--            size="mini"-->
<!--            type="text"-->
<!--            icon="el-icon-edit"-->
<!--            @click="handleDetail(scope.row)"-->
<!--          >详情</el-button>-->
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleCreateCode(scope.row)"
          >创建生成码</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改小程序题目管理对话框 -->
    <el-dialog title="获取推广码" :visible.sync="openDetail" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">

        <el-form-item label="小程序id" prop="code" >
          <el-input v-model="form.code" placeholder="推广码" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDetail()">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加或修改小程序题目管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="小程序id" prop="wxappId">
<!--          <el-input v-model="form.wxappId" placeholder="请输入小程序id" />-->
          <el-select v-model="form.wxappId" placeholder="请选择小程序">

            <el-option  v-for="item in WxappList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.appid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目id" prop="topicId">
<!--          <el-input v-model="form.topicId" placeholder="请输入题目id" />-->
          <el-select v-model="form.topicId" placeholder="请选择类型">

            <el-option  v-for="item in topicList"
                        :key="item.value"
                        :label="item.title"
                        :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
       <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listWxappTopic, getWxappTopic, delWxappTopic, addWxappTopic, updateWxappTopic, exportWxappTopic ,updateCreateCode} from "@/api/work/WxappTopic";
import { listWxapp} from "@/api/work/Wxapp";
import { listTopic} from "@/api/work/Topic";
export default {
  name: "WxappTopic",
  components: {
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 小程序题目管理表格数据
      WxappTopicList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层
      openDetail: false,
      openCreateCode:false,
      // 主键字典
      idOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      },
      //小程序列表
      WxappList:[],
      //问题列表
      topicList:[],
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
  },
  methods: {
    /** 查询小程序题目管理列表 */
    getList() {
      this.loading = true;
      listWxappTopic(this.queryParams).then(response => {
        this.WxappTopicList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消按钮
    cancelDetail() {
      this.openDetail = false;
    },

    // 表单重置
    reset() {
      this.form = {
        id: null,
        wxappId: null,
        topicId: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getWxappList();
      this.getTopicList();
      this.open = true;
      this.title = "添加小程序题目管理（非测评小程序不要分配该菜单）";
    },
    /** 修改按钮操作 */
    handleDetail(row) {
      this.reset();
      const id = row.id || this.ids
      getWxappTopic(id).then(response => {
        this.form = response.datas;
        this.openDetail = true;
      });
    },
    /** 创建生成码按钮操作 */
    handleCreateCode(row) {

      const id = row.id || this.ids

      this.$confirm('创建推广码前小程序必须上线，否则图片显示失败，是否确认创建?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return updateCreateCode({"id":id});
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("创建成功");
      })

      // updateCreateCode({"id":id}).then(response => {
      // this.getList()
      // });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateWxappTopic(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addWxappTopic(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除小程序题目管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delWxappTopic(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有小程序题目管理数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportWxappTopic(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    },
    /** 查询小程序管理列表 */
    getWxappList() {
      this.loading = true;
      listWxapp().then(response => {
        this.WxappList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },

    /** 查询小程序管理列表 */
    getTopicList() {
      this.loading = true;
      listTopic().then(response => {
        this.topicList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
  }
};
</script>
