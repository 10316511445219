import request from '@/utils/request'

// 查询小程序题目管理列表
export function listWxappTopic(query) {
  return request({
    url: '/work/WxappTopic/list',
    method: 'get',
    params: query
  })
}

// 查询小程序题目管理详细
export function getWxappTopic(id) {
  return request({
    url: '/work/WxappTopic/' + id,
    method: 'get'
  })
}

// 新增小程序题目管理
export function addWxappTopic(data) {
  return request({
    url: '/work/WxappTopic',
    method: 'post',
    data: data
  })
}

// 修改小程序题目管理
export function updateWxappTopic(data) {
  return request({
    url: '/work/WxappTopic/update',
    method: 'post',
    data: data
  })
}

// 创建小程序推广码
export function updateCreateCode(data) {
  return request({
    url: '/work/WxappTopic/createCode',
    method: 'post',
    data: data
  })
}

// 删除小程序题目管理
export function delWxappTopic(id) {
  return request({
    url: '/work/WxappTopic/remove/' + id,
    method: 'get'
  })
}

// 导出小程序题目管理
export function exportWxappTopic(query) {
  return request({
    url: '/work/WxappTopic/export',
    method: 'get',
    params: query
  })
}